import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {
    User,
    signUp,
    IRegistrationFormData,
    ILoginFormData,
    logIn,
    logOut,
    Permission,
    getUserPermissions, COOKIE_KEYS, getUserOrganisations,
    LOCAL_STORAGE_KEYS, IChangePasswordFormData, requestChangePassword
} from "lib/auth";
import {initializeParseServer} from "lib/parse";
import Cookies from "js-cookie";
import {FranchiseeMembership} from "../../../hubspot";

const Parse = require('parse/dist/parse');

interface AuthState {
    currentUser: User | Parse.User | null | undefined
    permissions: Permission[] | undefined,
    franchiseeMemberships: FranchiseeMembership[] | undefined
    isCurrent: () => boolean
    isAuthenticated: () => boolean
    signUp: (user?: User | IRegistrationFormData) => Promise<void>
    logIn: (user?: User | ILoginFormData) => Promise<void>
    changePassword: (user: User, data: IChangePasswordFormData) => Promise<void>
    setPermissions: (permissions: Permission[]) => void
    logOut: () => Promise<void>
}

// export const useAuthStore = create<AuthState>((set, get) => ({
//     currentUser: initializeParseServer(),
//     permissions: undefined,
//     isCurrent: () => get().currentUser?.isCurrent() ?? false,
//     isAuthenticated: () => get().currentUser?.authenticated() ?? false,
//     signUp: async (user?: User | IRegistrationFormData) => {
//         if (user instanceof User){
//             await user.signUp();
//         } else if (user){ // We assume IRegistrationFormData
//             await signUp(user as IRegistrationFormData);
//         }
//         set({ currentUser: Parse.User.current() })
//     },
//     logIn: async (user?: User | ILoginFormData) => {
//         if (user instanceof  User){
//             await user.login();
//         } else if (user){
//             await logIn(user as ILoginFormData);
//         }
//         const currentUser = Parse.User.current() as User;
//         const permissions:Permission[] = [];
//         for (const role of currentUser.roles){
//             permissions.push(
//                 ...(await role.permissions.query().includeAll().find({sessionToken: currentUser.get('sessionToken')}))
//             );
//         }
//
//         // const userDependencies =  await getUserDependencies(currentUser.id);
//         // const _currentUser = currentUser.toJSON();
//         // _currentUser['roles'] = userDependencies.roles;
//         // _currentUser['franchiseeMemberships'] = userDependencies.franchiseeMemberships
//         // localStorage.setItem('currentUserInfo',JSON.stringify(_currentUser));
//         set({ currentUser: (currentUser) })
//     },
//     logOut: async () => {
//         await logOut();
//         set({ currentUser: (Parse.User.current() as User) });
//         localStorage.removeItem('currentUserInfo');
//     }
// }));

export const useAuthStore = create<AuthState>()(
    persist(
        (set, get) => ({
            currentUser: initializeParseServer(),
            permissions: [],
            franchiseeMemberships:[],
            isCurrent: () => get().currentUser?.isCurrent() ?? false,
            // isVerified: () => get().currentUser.get('verifiedEmail'),
            isAuthenticated: () => get().currentUser?.authenticated() ?? false,
            signUp: async (user?: User | IRegistrationFormData) => {
                if (user instanceof User){
                    await user.signUp();
                } else if (user){ // We assume IRegistrationFormData
                    await signUp(user as IRegistrationFormData);
                }
                set({ currentUser: Parse.User.current() })
            },
            logIn: async (user?: User | ILoginFormData) => {
                if (user instanceof  User){
                    await user.login();
                } else if (user){
                    await logIn(user as ILoginFormData);
                }

                const currentUser = Parse.User.current();
                set({ currentUser, permissions: undefined});
                set({ currentUser, franchiseeMemberships: undefined});
                let _permissions =  await getUserPermissions(user as User);
                let _franchiseeMemberships =  await getUserOrganisations(currentUser);
                set({currentUser, franchiseeMemberships: _franchiseeMemberships})
                set({ currentUser, permissions: _permissions})
                localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_TIME_KEY, `${new Date().getTime()}`);
            },
            logOut: async () => {
                await logOut();
                set({ currentUser: (Parse.User.current() as User), permissions:undefined, franchiseeMemberships:undefined});
                localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGIN_TIME_KEY);
            },
            setPermissions: (permissions: Permission[])=>{
                set({currentUser: (Parse.User.current() as User), permissions: permissions });
            },
            changePassword: async (user : User, data:  IChangePasswordFormData) => {
                    await requestChangePassword(user,data);
            },

        }),
        {
            name: 'authStore',
            partialize: (state) => ({ permissions: state.permissions,franchiseeMemberships: state.franchiseeMemberships })
        }
    )
);


