import { Suspense, useEffect } from 'react';
import {Navigate, Outlet, useLocation, useSearchParams} from 'react-router-dom';
import { changeBodyAttribute } from 'utils';
import {useAuthStore, useLayoutStore} from "../lib/state/zustand";
import {decryptString, DEFAULT_PRIVATE_ROUTE} from "../lib/common";

const loading = () => <div className=""></div>;

type DefaultLayoutProps = {};

const DefaultLayout = (props: DefaultLayoutProps) => {
    const location = useLocation();
    const [searchParams,] = useSearchParams();

    const layoutColor = useLayoutStore((state) => state.layoutColor);

    const { isCurrent, isAuthenticated, currentUser  } = useAuthStore((state) => ({
        isCurrent: state.isCurrent,
        isAuthenticated: state.isAuthenticated,
        currentUser: state.currentUser
    }));

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);

    const pathname = location.pathname.toLowerCase();

    if (pathname.startsWith('/account')){        
        if (isAuthenticated()){ // Logged in user
            if (pathname.startsWith('/account/logout')){ // Only allow access to logout route
                return (<Suspense fallback={loading()}><Outlet /></Suspense>)
            } else {
                return (<Navigate to={ DEFAULT_PRIVATE_ROUTE } />)
            }
        } 
        //Todo: Ensure this user has permission to approve another user.
        else if (pathname.startsWith('/account/approve-user')){            
            //do nothing
            // return (<Navigate to={'/account/approve-user'} />)
        }
    }

    // Allow users - logged in or not - to access any route that is not under /account
    return (<Suspense fallback={loading()}><Outlet /></Suspense>);
};
export default DefaultLayout;
