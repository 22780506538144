import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    NAME_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PASSWORD_REGEX,
    useTogglePassword,
    useAlerts, sleep
} from "../../common";
import {
    IChangePasswordFormCallback,
    IChangePasswordFormData,
    IForgotPasswordFormCallback, IForgotPasswordFormData,
    ILoginFormCallback,
    ILoginFormData,
    IRegistrationFormData,
    VerificationCodeData,
    VerificationCodeParams
} from "./user.interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useAuthStore} from "../../state/zustand";
import { requestPasswordReset, validateVerificationCode} from "./user.services";
import {useEffect, useState} from "react";
import {User} from "./user.models";

const Parse = require('parse/dist/parse');

export function useAuthNavigation() { 
    const navigate = useNavigate();
    const logOut = useAuthStore((state) => state.logOut);

    const backToLogin = () => {
        logOut().then(() => {
            navigate('/account/login')
        })
    };

    const backToRegister = () => {
        logOut().then(() => {
            navigate('/account/register')
        })
    };

    return {
        backToLogin,
        backToRegister
    }
}

export function useUserRegistrationForm() {
    const { t } = useTranslation();
    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    const [formData, setFormData] = useState<Partial<IRegistrationFormData> | undefined>();

    // We are using signUp function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    const signUp = useAuthStore((state) => state.signUp);    
    
    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        firstName: yup.string().trim()
            .required(t('signup.form.firstName.errors.required'))
            .max(NAME_MAX_LENGTH, t('signup.form.firstName.errors.max', {max: NAME_MAX_LENGTH})),
        lastName: yup.string().trim()
            .required(t('signup.form.lastName.errors.required'))
            .max(NAME_MAX_LENGTH, t('signup.form.lastName.errors.max', {max: NAME_MAX_LENGTH})),
        email: yup.string().trim()
            .required(t('signup.form.email.errors.required'))
            .email(t('signup.form.email.errors.email')),
        password: yup.string().trim()
            .required(t('signup.form.password.errors.required'))
            .min(PASSWORD_MIN_LENGTH, t('signup.form.password.errors.min', {min: PASSWORD_MIN_LENGTH}))
            .max(PASSWORD_MAX_LENGTH, t('signup.form.password.errors.max', {max: PASSWORD_MAX_LENGTH}))
            .matches(PASSWORD_REGEX, t('signup.form.password.errors.regex')),
        acceptTerms: yup.boolean()
            .required(t('signup.form.acceptTerms.errors.required'))
            .isTrue(t('signup.form.acceptTerms.errors.required'))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IRegistrationFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IRegistrationFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }

        // Call submit callback function if one was provided.
        await signUp(data).then(() => {
            // Take note that password is replaced immediately after signup completed.
            setFormData((prev) => ({...prev, ...data, password: '#'}));
            reset();
        }).catch((error) => {
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.USERNAME_TAKEN:
                        setError('email', { message: t('signup.form.username.errors.taken') })
                        break;
                    case Parse.Error.EMAIL_TAKEN:
                        setError('email', { message: t('signup.form.email.errors.taken') })
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });

        // Redirect to the provided path if one was provided.
        // if (params?.redirect){ navigate(params?.redirect); }

    }; 

    const onVerificationSuccess = async (code: string | number) => {
        // No need to hide code as it is already consumed.
        setFormData((prev) => ({...prev, code: code}));
    };

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        formData,
        onVerificationSuccess,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useUserLoginForm(params?: ILoginFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    const [formData, setFormData] = useState<Partial<ILoginFormData> | undefined>();

    // We are using login function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    const logIn = useAuthStore((state) => state.logIn);

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        username: yup.string().trim()
            .required(t('signin.form.username.errors.required')),
        password: yup.string().trim()
            .required(t('signin.form.password.errors.required'))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<ILoginFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: ILoginFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }

        // This is expected to fail since code is not provided at first.
        await logIn(data).catch((error) => {
            if (error instanceof Parse.Error){
                switch (error.code) {
                    // This is used expressedly to help move the user to the validation page.
                    // This means that the sign in succeeded but pending verification.
                    case Parse.Error.COMMAND_UNAVAILABLE: 
                        // For now, let's just store the data temprarily.
                        setFormData((prev) => ({...prev, ...data}));
                        reset();
                        break;
                    default:
                        handleError(error);
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });        
    };    

    const onVerificationSuccess = async (code: string | number) => {
        // This is expected to succeed only if the code is correct.
        await logIn({username: formData!.username!, password: formData!.password!, code: code}).then(() => {
            navigate({
                pathname: searchParams.get('redirect') ? `${searchParams.get('redirect')}` : '/welcome',
            });
        }).catch(handleError);
    };

    function handleError (error: any){
        //Todo: Process errors here.
        if (error instanceof Parse.Error){
            switch (error.code) {
                case Parse.Error.OPERATION_FORBIDDEN:
                    setAlert({message: t('signin.form.submit.errors.forbidden'), variant: "danger"});
                    break;         
                case Parse.Error.OBJECT_NOT_FOUND:
                    setAlert({message: t('signin.form.submit.errors.invalidCredentials'), variant: "danger"});
                    break;         
                case Parse.Error.VALIDATION_ERROR:
                    setAlert({message: t('signin.form.submit.errors.invalidVerificationCode'), variant: "danger"});
                    break;         
                case Parse.Error.EXCEEDED_QUOTA:
                    setAlert({message: t('signin.form.submit.errors.maxRequestExceeded'), variant: "danger"});
                    break;         
                case Parse.Error.CONNECTION_FAILED:
                    setAlert({message: t('common.errors.connection'), variant: "danger"});
                    break;
                case Parse.Error.INTERNAL_SERVER_ERROR:
                    setAlert({message: t('common.errors.server'), variant: "danger"});
                    break;
                default:
                    setAlert({message: t(error.message), variant: "danger"});
                    break;
            }
        } else {
            setAlert({message: t(error.message), variant: "danger"});
        }
    }

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        formData,
        onVerificationSuccess,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useUserVerificationCodeForm({email, successCallback, action='signin'}: VerificationCodeParams) {
    const { t } = useTranslation();
    // Message utility helpers
    const { alert, setAlert } = useAlerts();
    
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        code: yup.string()
            .required(t('verify.form.code.errors.required'))
            .max(6, t('verify.form.code.errors.max', {max: 6}))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<{code: string|number}>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: VerificationCodeData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }

        if (!email?.trim()){
            setAlert({message: t('verify.form.code.errors.emailRequired'), variant: "danger"});
            return;
        }

        // For sign in, we will pass on the code to complete the login flow
        // Calling validateVerificationCode for signin will consume the code in the backend and 
        // this will break effective login which passes the code as context.
        if (action === 'signin'){
            await successCallback?.(data.code);
            reset();
            return;
        }
        
        await validateVerificationCode({action, email, code: data.code}).then(async () => {
            await successCallback?.(data.code); // Call the success callback with the code.
            reset();
        }).catch((error) => {
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.OBJECT_NOT_FOUND:
                        setAlert({message: t('verify.form.code.errors.notFound'), variant: "danger"});
                        break;
                    case Parse.Error.VALIDATION_ERROR:
                        // setAlert({message: t('common.errors.connection'), variant: "danger"});
                        setError('code', { message: t('verify.form.code.errors.invalid') })
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });
    };

    return {
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert,
        setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useForgotPasswordForm(params?: IForgotPasswordFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        email: yup.string().trim()
            .required(t('forgotPassword.form.email.errors.required'))
            .email(t('forgotPassword.form.email.errors.email')),
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IForgotPasswordFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IForgotPasswordFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        // Set the submitCallback to signUp service function if one was not set
        const submitCallback = params?.submitCallback ?? requestPasswordReset;
        // Call submit callback function if one was provided.
        await submitCallback?.(data).then(() => {
            setAlert({message: t('forgotPassword.form.submit.success'), variant: "success"});
        }).catch((error) => {
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    // case Parse.Error.OTHER_CAUSE:
                    //     setAlert({message: t('common.errors.unknown'), variant: "danger"});
                    //     break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });

        // Redirect to the provided path if one was provided.
        if (params?.redirect){ navigate(params?.redirect); }
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useLogout() {
    const { t } = useTranslation();
    const logOut = useAuthStore((state) => (state.logOut));

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    useEffect(() => {
        logOut().catch((error) => {
            setAlert({message: `${t('common.errors.unknown')} :: ${error.message}`, variant: "danger"});
        });
    }, [logOut]);

    return {
        alert, setAlert
    }
}
export function useChangePasswordForm(params?: IChangePasswordFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // We are using login function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    //todo comment the line below when sendgrid maximum quota is resolved
    const {changePassword, logOut,currentUser} = useAuthStore((state) => (
        {
            currentUser: state.currentUser,
            changePassword: state.changePassword,
            logOut: state.logOut
        }));

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        currentPassword: yup.string().trim()
            .required(t('myAccount.changePassword.form.currentPassword.errors.required')),
        newPassword: yup.string().trim()
            .required(t('myAccount.changePassword.form.newPassword.errors.required'))
            .min(PASSWORD_MIN_LENGTH, t('myAccount.changePassword.form.newPassword.errors.min', {min: PASSWORD_MIN_LENGTH}))
            .max(PASSWORD_MAX_LENGTH, t('myAccount.changePassword.form.newPassword.errors.max', {max: PASSWORD_MAX_LENGTH}))
            .matches(PASSWORD_REGEX, t('myAccount.changePassword.form.newPassword.errors.regex')),
        confirmPassword: yup.string()
            .required(t('myAccount.changePassword.form.confirmPassword.errors.required'))
            .oneOf([yup.ref('newPassword')], t('myAccount.changePassword.form.confirmPassword.errors.match'))

    })

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IChangePasswordFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IChangePasswordFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        try{
            // Call submit callback function if one was provided.
            await changePassword(currentUser as User,data);
            reset();
            setAlert({message: t('myAccount.changePassword.form.success.passwordChanged'), variant: "success"});
            await sleep(4500);
            await logOut();

        }catch (error: any) {
            console.log(error)
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.OBJECT_NOT_FOUND:
                        setAlert({message: (!error.message.includes("username/password") ? error.message :
                                t('myAccount.changePassword.form.errors.incorrectCurrentPassword') ), variant: "danger"});
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        }
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}